








import { Component, Vue } from "vue-property-decorator";
import EliminarCuenta from "@/components/Common/EliminarCuenta.vue";

@Component({
  components: {
    EliminarCuenta
  }
})
export default class EliminarCuentaView extends Vue {}
